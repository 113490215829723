import {
  MeterProvider,
  PeriodicExportingMetricReader,
} from '@opentelemetry/sdk-metrics';
import { OTLPMetricExporter } from '@opentelemetry/exporter-metrics-otlp-http';
import { Attributes, Counter, Histogram } from '@opentelemetry/api';

const collectorOptions = {
  url: process.env.REACT_APP_OTEL_METRIC_COLLECTOR_URL,
  concurrencyLimit: 1,
};

const metricExporter = new OTLPMetricExporter(collectorOptions);
const meterProvider = new MeterProvider({
  readers: [
    new PeriodicExportingMetricReader({
      exporter: metricExporter,
      exportIntervalMillis: 30000,
    }),
  ],
});
const meter = meterProvider.getMeter('pre-crime');

enum MetricType {
  COUNTER = 'counter',
  HISTOGRAM = 'histogram',
}

const METRIC_SOURCE = 'agent_portal';

interface InstrumentDefinition {
  name: string;
  type: MetricType;
  description: string;
}

export enum INSTRUMENTS {
  EVENT_PRESENTED_TO_AGENT = 'event_presented_to_agent',
  EVENT_EXITED_FEED = 'event_exited_feed',
  EVENT_TIME_SPENT_IN_FEED = 'event_time_spent_in_feed',

  EVENT_VERIFICATION_START = 'event_started_verification',
  EVENT_VERIFICATION_END = 'event_ended_verification',
  EVENT_VERIFICATION_DURATION = 'event_verification_duration',

  EVENT_STARTED_FOLLOWUP = 'event_started_followup',
  EVENT_ENDED_FOLLOWUP = 'event_ended_followup',
  EVENT_FOLLOW_UP_DURATION = 'event_followup_duration',

  AGENT_ENTERED_FEED = 'agent_entered_feed',
  AGENT_ENTERED_WORK_STATE = 'agent_entered_work_state',
  AGENT_EXITED_WORK_STATE = 'agent_exited_work_state',
  AGENT_EXITED_FEED = 'agent_exited_feed',
  AGENT_TIME_SPENT_IN_FEED = 'agent_time_spent_in_feed',
  AGENT_FEED_SLOT_OCCUPANCY_CHANGED = 'agent_feed_slot_occupancy_changed',

  AGENT_2_WAY_AUDIO_UNMUTE = 'agent_2_way_audio_unmute',
  AGENT_2_WAY_AUDIO_MUTE = 'agent_2_way_audio_mute',
  AGENT_2_WAY_AUDIO_CONNECTION_SUCCESS = 'agent_2_way_audio_connection_success',
  AGENT_2_WAY_AUDIO_CONNECTION_FAILURE = 'agent_2_way_audio_connection_failure',
  AGENT_2_WAY_AUDIO_DURATION = 'agent_2_way_audio_connection_duration',
}

// Create instruments for everything we want to track
const INSTRUMENT_DEFINITIONS: InstrumentDefinition[] = [
  {
    name: INSTRUMENTS.EVENT_VERIFICATION_START,
    type: MetricType.COUNTER,
    description:
      'When a specialist starts verifying an event (enters verify and respond page)',
  },
  {
    name: INSTRUMENTS.EVENT_VERIFICATION_END,
    type: MetricType.COUNTER,
    description:
      'When a specialist stopped verifying the event (leaves verify and respond page)',
  },
  {
    name: INSTRUMENTS.EVENT_VERIFICATION_DURATION,
    type: MetricType.HISTOGRAM,
    description: 'Elapsed milliseconds that the event was in Verify-Respond',
  },
  {
    name: INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_SUCCESS,
    type: MetricType.COUNTER,
    description: 'When an agent successfully connects to 2-way audio',
  },
  {
    name: INSTRUMENTS.AGENT_2_WAY_AUDIO_CONNECTION_FAILURE,
    type: MetricType.COUNTER,
    description: 'When an agent fails to connect to 2-way audio',
  },
  {
    name: INSTRUMENTS.AGENT_2_WAY_AUDIO_UNMUTE,
    type: MetricType.COUNTER,
    description:
      'Any time an agent unmutes a camera to activate 2WA. This could be by clicking to unmute (full duplex cams) or pressing and holding the mic button (half duplex VDP)',
  },
  {
    name: INSTRUMENTS.AGENT_2_WAY_AUDIO_MUTE,
    type: MetricType.COUNTER,
    description:
      'Any time an agent mutes a camera to deactivate 2WA. This could be by clicking to mute (full duplex cams) or releasing the mic button (half duplex VDP)',
  },
  {
    name: INSTRUMENTS.EVENT_FOLLOW_UP_DURATION,
    type: MetricType.HISTOGRAM,
    description: 'Elapsed milliseconds that the event was in Follow-Up',
  },
  {
    name: INSTRUMENTS.AGENT_ENTERED_WORK_STATE,
    type: MetricType.COUNTER,
    description: 'When an agent enters the work state',
  },
  {
    name: INSTRUMENTS.AGENT_EXITED_WORK_STATE,
    type: MetricType.COUNTER,
    description: 'When an agent exits the work state',
  },
  {
    name: INSTRUMENTS.EVENT_TIME_SPENT_IN_FEED,
    type: MetricType.HISTOGRAM,
    description: 'Elapsed milliseconds that the event was in the feed',
  },
  {
    name: INSTRUMENTS.EVENT_EXITED_FEED,
    type: MetricType.COUNTER,
    description: 'How many events exited the feed',
  },
  {
    name: INSTRUMENTS.EVENT_STARTED_FOLLOWUP,
    type: MetricType.COUNTER,
    description: 'How many events started follow-up',
  },
  {
    name: INSTRUMENTS.AGENT_2_WAY_AUDIO_DURATION,
    type: MetricType.HISTOGRAM,
    description: 'Elapsed milliseconds that the agent was in 2-way audio',
  }
];

const counterInstruments: { [key: string]: Counter } =
  INSTRUMENT_DEFINITIONS.reduce((accumulator, current) => {
    if (current.type === MetricType.COUNTER) {
      accumulator[current.name] = meter.createCounter(current.name, {
        description: current.description,
      });
    }
    return accumulator;
  }, {} as { [key: string]: Counter });

const histogramInstruments: { [key: string]: Histogram } =
  INSTRUMENT_DEFINITIONS.reduce((accumulator, current) => {
    if (current.type === MetricType.HISTOGRAM) {
      accumulator[current.name] = meter.createHistogram(current.name, {
        description: current.description,
      });
    }
    return accumulator;
  }, {} as { [key: string]: Histogram });

// The destination of Metrics is Grafana
// Attributes (tags) MUST be low-cardinality
interface Metric {
  name: string;
  value: number;
  attributes?: Attributes;
}

export const useAddCounter = () => {
  return (metric: Metric) => {
    const counterInstrument = counterInstruments[metric.name];
    if (!counterInstrument) {
      console.warn(
        `Counter instrument for metric ${metric.name} is not defined.`
      );
      return;
    }
    counterInstrument.add(metric.value, {
      ...metric?.attributes,
      source: METRIC_SOURCE,
    });
  };
};

export const useRecordMetric = () => {
  return (metric: Metric) => {
    const histogramInstrument = histogramInstruments[metric.name];
    if (!histogramInstrument) {
      console.warn(
        `Histogram instrument for metric ${metric.name} is not defined.`
      );
      return;
    }
    histogramInstrument.record(metric.value, {
      ...metric?.attributes,
      source: METRIC_SOURCE,
    });
  };
};
