import { INSTRUMENTS } from './metricHooks';
import { useEffect } from 'react';
import { useAgentMilestone } from './milestoneHooks';

export enum WorkStateAction {
  ACCEPT_NEW = 'accept_new',
  HANDLE_EVENT_VERIFY_AND_RESPONSE = 'handle_event_verify_and_response',
  HANDLE_EVENT_FOLLOW_UP = 'handle_event_follow_up',
}

export enum WorkStateExitAction {
  ACCEPT_NEW = 'accept_new',
  LOGOUT = 'logout',
  CLOSE_TAB = 'close_tab',
}

export const AGENT_ENTERED_WORK_STATE_AT = 'agentEnteredWorkStateAt';

const useAgentWorkState = () => {
  const sendAgentMetrics = useAgentMilestone();

  const setEnteredWorkState = (actionName: WorkStateAction) => {
    if (localStorage.getItem(AGENT_ENTERED_WORK_STATE_AT) == null) {
      sendAgentMetrics({
        agentId: localStorage.getItem('user_id'),
        metricName: INSTRUMENTS.AGENT_ENTERED_WORK_STATE,
        payload: {
          action_name: actionName,
        },
      });
      localStorage.setItem(
        AGENT_ENTERED_WORK_STATE_AT,
        new Date().toISOString()
      );
    }
  };

  const setExitedWorkState = async (exitActionName: WorkStateExitAction) => {
    if (localStorage.getItem(AGENT_ENTERED_WORK_STATE_AT) == null) {
      return;
    }

    localStorage.removeItem(AGENT_ENTERED_WORK_STATE_AT);
    return sendAgentMetrics({
      agentId: localStorage.getItem('user_id'),
      metricName: INSTRUMENTS.AGENT_EXITED_WORK_STATE,
      payload: {
        action_name: exitActionName,
      },
    });
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      setExitedWorkState(WorkStateExitAction.CLOSE_TAB);
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return {
    setEnteredWorkState,
    setExitedWorkState,
  };
};

export default useAgentWorkState;
