import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Card, Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import CancelEventMenu from 'src/components/CancelEventMenu';
import DenseCardHeader from 'src/components/DenseCardHeader';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
// import Overlay from 'src/components/Overlay/Overlay';
import VideoPlayer from 'src/components/VideoPlayer/index';
import { usePcsAuthRep } from 'src/context/pcs-auth-context';
import { useSubscriber } from 'src/context/sub-auth-context';
import { useSetPcsEventStatus } from 'src/hooks/eventHooks';
import { useSubscription } from 'src/hooks/useSubscription';
import { PcsStatus } from 'src/models';
import { makeMarkers } from 'src/utils/markers';
import { useTimer } from 'src/utils/useTimer';
import {
  MetricsContext,
  getEventInQueueDuration,
  createOpenMetricDispatch,
} from 'src/context/Metrics-context';
import MetricsModule from 'src/utils/MetricsModule';
import { useSendAutoNotify } from 'src/hooks/subscriberHooks';
import useEventExitedFeed, {
  EventExitedFeedReason,
} from 'src/hooks/useEventExitedFeed';

import { AgentExitedFeedReason } from 'src/hooks/useAgentExitedFeed';

import { useAddCounter, INSTRUMENTS } from 'src/hooks/metricHooks';

const useStyles = makeStyles((theme) => ({
  videoCard: {
    position: 'relative',
  },
}));

const MonitorItem = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [_, dispatch] = useContext(MetricsContext);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const {
    event,
    slot,
    playerState,
    autoExpire,
    payload,
    onClose,
    onPlayerClick,
    experimental,
    availableSlots,
    filledSlots,
    handleAgentExited,
  } = props;

  const {
    duration,
    eventId,
    eventTimestamp,
    faces,
    moves,
    sid,
    tracks,
    userId,
    uuid,
  } = event;

  const video = event.video[uuid];
  let playerType = 'flv';

  const shortTimestamp = String(eventTimestamp).slice(0, -5);
  const eventTimeAgo = moment.unix(eventTimestamp).fromNow();

  const markers = makeMarkers(tracks, moves, faces);
  const classes = useStyles();
  const previousScreen = localStorage.getItem('previous_screen');

  const pcsStatusMutation = useSetPcsEventStatus();
  const [closing, setClosing] = useState(false);

  const autoNotification = useSendAutoNotify(eventId, userId);

  const { handleEventExited } = useEventExitedFeed({ eventId, sid });

  const handleClose = (pcsStatus, ...rest) => {
    const cancelQueueEvent = new MetricsModule('event-canceled');
    let cancelQueueEventPayload;
    if (typeof payload !== 'object') {
      cancelQueueEvent.fetchNewData();
      cancelQueueEventPayload = cancelQueueEvent.payload();
    }
    dispatch({
      type: 'SEND',
      payload: {
        ...(cancelQueueEventPayload || payload),
        eventID: eventId,
        reasonCancelEvent: rest.length && rest[0]?.disposition_reason,
        metricName: 'event-canceled',
      },
    });
    setClosing(true);
    pcsStatusMutation.mutate({
      eventId,
      pcsStatus: pcsStatus,
      disposition_reason: rest.length && rest[0].disposition_reason,
      disposition_action: rest.length && rest[0].disposition_action,
    });
    autoNotification.send('common_event');

    // Send metric for how long the event was in the queue
    if (eventId) {
      const openMetricDispatch = createOpenMetricDispatch(dispatch);
      openMetricDispatch({
        metricName: 'event-handle-time',
        payload: {
          eventId,
          duration: getEventInQueueDuration(eventId),
          PCSstatus: 'queue',
        },
      });
    }
  };

  const expireTimer = useTimer({
    initialTime: autoExpire,
    timerType: 'DECREMENTAL',
    autostart: true,
    endTime: 0,
    onTimeOver: () => {
      handleEventExited({ reason: EventExitedFeedReason.EXPIRED });
      handleAgentExited({ reason: AgentExitedFeedReason.TIMEOUT });
      handleClose(-10);
    },
  });
  const formattedTime = new Date(expireTimer.time * 1000)
    .toISOString()
    .replace(/.*(\d{2}:)(\d{2}:\d{2}).*/, '$2');

  const handleSecond = useCallback(() => {
    if (expireTimer.status === 'RUNNING') {
      expireTimer.pause();
      return;
    }
    expireTimer.start();
  }, [expireTimer]);

  const handleEventFirstInteraction = () => {
    const reviewStartEvent = new MetricsModule('review-first-interaction');
    dispatch({
      type: 'SEND',
      payload: {
        ...reviewStartEvent,
        eventID: eventId,
        metricName: 'review-first-interaction',
      },
    });
  };

  const navigate = useNavigate();
  const handlePlayerClick = useCallback(() => {
    handleEventFirstInteraction();
    const url = `/app/verify-respond?userId=${userId}&sid=${sid}&eventId=${eventId}&uuid=${uuid}`;
    onPlayerClick(eventId);
    navigate(url);
    handleEventExited({ reason: EventExitedFeedReason.MOVED_TO_VR });
    handleAgentExited({ reason: AgentExitedFeedReason.TAKEN_INTO_VR });
  }, [
    eventId,
    navigate,
    onPlayerClick,
    sid,
    userId,
    uuid,
    availableSlots,
    filledSlots,
  ]);

  const { authHeader: realAuthHeader } = useSubscriber(userId);
  const authHeader = event.isFake ? 'Bearer Fake' : realAuthHeader;
  const { token } = usePcsAuthRep();

  // Don't need any return from this, but let's start caching this data so that going to the event page is fast!
  useSubscription({ userId, sid });
  const addCounter = useAddCounter();

  useEffect(() => {
    // Save the time when this event was first available in queue
    // This is later used to record the total duration of the event in queue
    sessionStorage.setItem(
      `event_in_queue_start_${eventId}`,
      new Date().getTime().toString()
    ); // Everything in session/local storage is saved as a string

    // Send this event to FullStory as a custom event
    FS('trackEvent', {
      name: 'event_in_queue',
      properties: {
        event_id: eventId,
      },
    });
  }, [eventId]);

  React.useEffect(() => {
    if (pcsStatusMutation.isSuccess) {
      onClose();
    }
    return () => {
      // cleanup
    };
  }, [pcsStatusMutation.isSuccess, onClose]);

  if (pcsStatusMutation.isSuccess) {
    return <div>Removed</div>;
  }

  if ((closing && !pcsStatusMutation.isError) || !authHeader) {
    return <LoadingSpinner />;
  }

  let video_url = `${process.env.REACT_APP_PCS_ADMIN_URL}/video/video?file_path=${event.video_path}.mp4`;

  if (playerType === 'flv') {
    video_url = `${process.env.REACT_APP_PCS_ADMIN_WS_URL}/video/ws?file_path=${event.video_path}&token=${token}`;
  }

  if (video.recordingType === 'KVS') {
    video_url = event.hls_url;
    playerType = 'kvs-experiment';
  }

  return (
    <Card>
      <Grid container direction="row" justifyContent="space-between">
        <DenseCardHeader
          title={`[${slot}] ${event.info.replace(
            'Detected Motion',
            ''
          )} ${eventTimeAgo} (Alarm ${event.alarmState})`}
          second={`[${formattedTime}]`}
          close
          onClose={() => {
            handleClose(-30);
          }}
          onSecond={handleSecond}
          handleClick={handleClick}
        />
      </Grid>
      <div
        id={`player-${uuid}-${eventId}`}
        className={`${classes.videoCard}`}
        onMouseOver={handleEventFirstInteraction}
      >
        <VideoPlayer
          {...playerState}
          authHeader={authHeader} // Need to set individual authHeader here for TalkSocket
          controls={['play', 'time', 'progress', 'volume']}
          duration={duration}
          eventId={eventId}
          handleZoom={false}
          isLive={false}
          markers={markers}
          mesuredView={'queue'}
          onPlayerClick={handlePlayerClick}
          showLiveIndicator={false}
          sid={sid}
          unmuteOnHoverEnabled
          type={playerType}
          url={video_url}
          uuid={uuid}
          video={video}
        />
        <CancelEventMenu
          anchorEl={anchorEl}
          open={open}
          close={() => setAnchorEl(null)}
          select={({ action, reason }) => {
            handleEventExited({
              reason: EventExitedFeedReason.CANCELED_FROM_FEED,
              disposition_action: action,
              disposition_reason: reason,
            });

            addCounter({ name: INSTRUMENTS.EVENT_VERIFICATION_END, value: 1 });

            handleClose(PcsStatus.operator_handled, {
              disposition_action: action,
              disposition_reason: reason,
            });
          }}
          excludeItems={[
            'Duplicate Event',
            'Delivery',
            'Outdoor Service',
            'Household Activity',
            'Technical Issue',
          ]}
        />
      </div>
    </Card>
  );
};

MonitorItem.propTypes = {
  event: PropTypes.object.isRequired,
  slot: PropTypes.number,
  playerState: PropTypes.object,
  isPlaying: PropTypes.bool,
  showStats: PropTypes.bool,
  predicting: PropTypes.bool,
  volume: PropTypes.number,
  autoExpire: PropTypes.number,
  onClose: PropTypes.func,
  onPlayerClick: PropTypes.func,
  payload: PropTypes.object,
  experimental: PropTypes.bool,
  availableSlots: PropTypes.number,
  filledSlots: PropTypes.number,
  handleAgentExited: PropTypes.func,
};

export default MonitorItem;
