import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useAppTheme } from 'src/context/theme-context';
import KeyboardDialog from 'src/components/KeyboardDialog';
import { usePcsAuthRep } from 'src/context/pcs-auth-context';
import { useNavigate } from 'react-router';
import useAgentWorkState, {
  WorkStateExitAction,
} from 'src/hooks/useAgentWorkState';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.appBar,
  },
  avatar: {
    width: 60,
    height: 60,
  },
  testingMode: {
    justifyContent: 'center',
    display: 'flex',
    color: 'yellow',
  },
}));

const TopBar = ({ className, onMobileNavOpen, title, stats, ...rest }) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const { setThemeName } = useAppTheme();
  const { logout } = usePcsAuthRep();
  const navigate = useNavigate();
  const { setExitedWorkState } = useAgentWorkState();

  const handleLogout = async () => {
    await setExitedWorkState(WorkStateExitAction.LOGOUT);
    console.log('Logging out, but not redirecting (yet)');
    setTimeout(() => logout(), 1000);
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar disableGutters>
        <IconButton size="small" color="inherit" onClick={onMobileNavOpen}>
          <MenuIcon fontSize="small" />
        </IconButton>
        {/* <RouterLink to="/">
          <Typography color="">Home</Typography>
        </RouterLink> */}
        <Typography>{title}</Typography>
        <Box flexGrow={1} />
        <Typography>{stats}</Typography>
        <Box flexGrow={1} />
        <Hidden xsDown>
          <IconButton size="small" color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon fontSize="small" />
            </Badge>
          </IconButton>
          <IconButton
            size="small"
            color="inherit"
            onClick={() => navigate('/app/account')}
            title="Account"
          >
            <AccountCircleIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            color="inherit"
            onClick={handleLogout}
            title="Logout"
          >
            <InputIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            color="inherit"
            onClick={setThemeName}
            title="Theme"
          >
            <Brightness4Icon fontSize="small" />
          </IconButton>
          <KeyboardDialog />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  title: PropTypes.string,
  stats: PropTypes.object,
};

export default TopBar;
