import { useEffect, useState } from 'react';
import { useAlarmState } from 'src/hooks/useAlarmState';
import { useSetPcsEventStatus } from './eventHooks';
import { INSTRUMENTS, useAddCounter } from 'src/hooks/metricHooks';
import { Action, PcsStatus } from 'src/models';
import { useSendAutoNotify } from './subscriberHooks';
import { getEventInQueueDuration } from 'src/context/Metrics-context';
import useEventEndedVerication, {
  EventEndedReason,
} from 'src/hooks/useEventEndedVerification';

export function useDisarmHook({
  subscription,
  setIsDisarmed,
  isDisarmed,
  userId,
  eventId,
}) {
  const [prevState, setPrevState] = useState(null);
  const autoNotification = useSendAutoNotify(eventId, userId);
  const pcsStatusMutation = useSetPcsEventStatus();
  const alarmStateQuery = useAlarmState({
    sid: subscription?.sid,
    account: subscription?.location.account,
    userId: subscription?.uid,
  });

  const addCounter = useAddCounter();
  const { handleEventEnded } = useEventEndedVerication({
    eventId,
    sid: subscription?.sid,
  });

  useEffect(() => {
    if (alarmStateQuery?.data && alarmStateQuery?.data?.state !== prevState) {
      if (alarmStateQuery?.data?.state === 'OFF' && prevState !== null) {
        setIsDisarmed(true);
      }
      setPrevState(alarmStateQuery?.data?.state);
    }
  }, [prevState, alarmStateQuery?.data]);

  useEffect(() => {
    if (!isDisarmed || !eventId) {
      return;
    }

    pcsStatusMutation.mutate(
      {
        eventId,
        pcsStatus: PcsStatus.canceled,

        disposition_reason: 'System was disarmed',
        disposition_action: Action.canceled_by_customer,
      },
      {
        onSettled: () => {
          autoNotification.send('system_disarm');

          // Follow Up Ended Metric
          dispatchFollowUpEventEnded(EventEndedReason.CANCELED_FROM_VR);

          //Verification Event ended
          handleEventEnded({
            reason: EventEndedReason.CANCELED_FROM_VR,
            cancel_reason: 'disarmed',
            disposition_action: Action.canceled_disarm,
          });

          // Send metric for how long the event was in the queue
          if (eventId) {
            openMetricDispatch({
              metricName: 'event-handle-time',
              payload: { eventId, duration: getEventInQueueDuration(eventId) },
            });
          }
        },
      }
    );
  }, [isDisarmed, eventId]);

  const dispatchFollowUpEventEnded = (reason = '') => {
    addCounter({ name: INSTRUMENTS.EVENT_ENDED_FOLLOWUP, value: 1 });
    sendAgentMetrics({
      eventId: eventId,
      metricName: INSTRUMENTS.EVENT_ENDED_FOLLOWUP,
      payload: {
        agent_id: localStorage.getItem('user_id'),
        location_id: subscription?.sid,
        disposition_action: Action.system_disarm,
        reason,
        cancel_reason: 'disarmed',
      },
    });
  };
}
